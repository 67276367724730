import React, { useState, useContext, useEffect, useRef } from 'react'
import classnames from 'classnames'
import xss from 'xss'
import Context from 'ima/page/context'
import RouterEvents from 'ima/router/Events'
import SspHelper from 'app/helpers/sspHelper/SspHelper'
import { getRibbonHeight } from '@inzeraty/components'
import { RESPONSIVE } from 'app/base/Constants'
import ROUTE_NAMES from 'app/base/RouteNames'
import SspCollocationOptions from 'app/helpers/sspCollocation/SspCollocationOptions'
import { RENDER_BRANDING_EVENT } from 'app/component/layout/LayoutView'
import UserEntityContext from 'app/component/managedRootView/UserEntityContext'
import SspContext from '../SspContext'

import './Branding.less'

const CLASSNAME = 'c-ssp-branding'

const ID = 'branding'
const BRANDING_SMALL_WIDTH = 970 // pri zmene upravit i placeholder v less souboru
const DEFAULT_BRANDING_HEIGHT = 226 // pri zmene upravit i placeholder v less souboru
const BRANDING_SUCCESS_CLASSNAME = `${CLASSNAME}__success`
const BRANDING_JSON_CLASSNAME = `${CLASSNAME}__json`
const BRANDING_IFRAME_CLASSNAME = `${CLASSNAME}__iframe`

const BRANDING_INNER_ELEM_ID = 'brandingInnerId'

const IFRAME_LAYOUT_WIDTH = 2000 // ak pride tato sirka reklamy, layout stranky musi byt 1060px

/**
 * Jednotlive typy reklam, ktore sa mozu vydavat
 */
const BRNADING_FORMATS = {
	IFRAME: 'iframe',
	IFRAME_URL: 'iframe_url',
	CODE: 'code'
}

/**
 * Jednotlive typy reklam pre branding
 * reklamu typu CODE (JSON) - jedna sa o reklamu, ktora
 * sa zobrazuje ako ram okolo celej stranky
 */
const BRANDING_JSON_TYPES = {
	HTML: 'html5',
	IMAGE: 'image',
	CODE: 'code'
}

const BRANDING_NO_RAM_ZONE_SIZES = {
	width: 970,
	height: 210
}

const BRANDING_RAM_ZONE_SIZES = {
	width: 2560,
	height: 1440
}

const _isBrandingRamMinWidth = () => {
	const { matches } = window.matchMedia(`(min-width: ${RESPONSIVE.DESKTOP_BRANDING_RAM / 16}em)`)
	return matches
}

const _getCollocation = (options = {}) => {
	const BRANDING_COLLOCATION = 'RAM'
	let { collocation = '' } = options

	if (_isBrandingRamMinWidth()) {
		collocation = `${BRANDING_COLLOCATION} ${collocation}`
	}
	return collocation
}

const _getZoneSizes = () => {
	if (_isBrandingRamMinWidth()) {
		return BRANDING_RAM_ZONE_SIZES
	} else {
		return BRANDING_NO_RAM_ZONE_SIZES
	}
}

const renderElementForBranding = (brandingRef) => {
	const brandingElement = document.createElement('div')
	brandingElement.id = BRANDING_INNER_ELEM_ID
	brandingRef.current?.appendChild(brandingElement)
}

const Branding = () => {
	const context = useContext(Context)
	const sspContextData = useContext(SspContext)
	const userEntity = useContext(UserEntityContext)

	const dispatcher = context.$Dispatcher
	const sspHelper = context.SspHelper
	const $window = context.$Window
	const router = context.$Router

	// priznak, zda si uzivatel plati "Seznam bez reklam" (SBR)
	const isSbrUser = userEntity?.isSbr

	const brandingRef = useRef(null)
	const brandingSizer = useRef(null)

	const [isAdEnabled, setIsAdEnabled] = useState(true)

	const customEvent = $window.createCustomEvent(SspHelper.SSP_CUSTOM_EVENTS.DESTROY, {
		bubbles: true,
		cancelable: true
	})

	useEffect(() => {
		// zjistime, jestli se povedlo nacist SSP skript, tedy jestli uzivatel nema zapnuty AdBlock atd.
		setIsAdEnabled(sspHelper.isEnabled())

		getAdForSsp(sspContextData)
		dispatcher.listen(RouterEvents.BEFORE_HANDLE_ROUTE, onBeforeHandleRoute)

		return () => {
			dispatcher.unlisten(RouterEvents.BEFORE_HANDLE_ROUTE, onBeforeHandleRoute)
		}
	}, [sspContextData])

	// pred zmenou routy alebo pri strankovani upraceme po reklame
	const onBeforeHandleRoute = () => {
		const { current: brandingRefCurrent } = brandingRef
		const { current: brandingSizerCurrent } = brandingSizer

		setIsAdEnabled(sspHelper.isEnabled())

		const bradingOuterElement = document.querySelector(`.${CLASSNAME}`)
		const brandingInnerElement = document.getElementById(BRANDING_INNER_ELEM_ID)

		if (brandingInnerElement) {
			bradingOuterElement.removeChild(brandingInnerElement)
		}

		if (brandingSizerCurrent) {
			brandingSizerCurrent.style.display = 'none'
		}
		brandingRefCurrent?.removeAttribute('style')

		// pri strankovani zavolam custom event nech si reklama uprace
		brandingRefCurrent?.dispatchEvent(customEvent)
	}

	const getAdForSsp = (pageData) => {
		const options = SspCollocationOptions.getOptions(pageData)

		if (options) {
			const collocation = _getCollocation(options)

			const updatedOptions = Object.assign({}, options, {
				collocation
			})

			getAd(updatedOptions)
		}
	}

	const getZoneId = () => {
		const { route = {} } = router.getCurrentRouteInfo()
		const routeName = route.getName()

		let zoneId = 72270 // vypis, detail prodejce, seznam prodejcu, ...

		if (routeName === ROUTE_NAMES.USERWEB.HOMEPAGE) {
			zoneId = 72271
		} else if (
			routeName === ROUTE_NAMES.USERWEB.ADVERT_DETAIL ||
			routeName === ROUTE_NAMES.USERWEB.OPERATING_LEASE_DETAIL ||
			routeName === ROUTE_NAMES.USERWEB.SELLER ||
			routeName === ROUTE_NAMES.USERWEB.SELLER_WITH_OPERATING_LEASES ||
			routeName === ROUTE_NAMES.USERWEB.SELLER_OLD
		) {
			zoneId = 72272
		}

		return zoneId
	}

	const getAd = (options = {}) => {
		const { matches } = window.matchMedia(`(min-width: ${RESPONSIVE.DESKTOP_BRANDING / 16}em)`)

		const { width, height } = _getZoneSizes()

		const zoneId = getZoneId()

		if (matches) {
			sspHelper.getAds([
				{
					zoneId,
					id: BRANDING_INNER_ELEM_ID,
					width,
					height,
					options,
					callback: (advert, zone) => {
						//posleme impress
						sendImpress(advert)

						setIsAdEnabled(true)

						if (advert.type !== 'empty') {
							renderElementForBranding(brandingRef)
							// vysku reklamy nastavujeme na tento element, kvoli reklame,
							// ktora vyzaduje animaciu obsahu stranky
							if (brandingSizer.current) {
								brandingSizer.current.style.height = DEFAULT_BRANDING_HEIGHT + 'px'
								brandingSizer.current.style.display = 'block'
							}

							const elementForAdDisplay = document.getElementById(BRANDING_INNER_ELEM_ID)

							// ak sa jedna o type = code ide o JSON ADVERT reklamu
							// o vykreslenie inych typov sa stara ssp script
							if (advert.type === BRNADING_FORMATS.CODE) {
								const data = sspHelper.sanitizeJsonAdvertData(advert.data)
								let parsedData

								try {
									parsedData = JSON.parse(data)
								} catch (error) {
									console.error('Branding rozbita reklama: ', advert)
									if (brandingSizer.current) {
										brandingSizer.current.style.display = 'none'
									}
									return
								}

								// custom event, ktory nastavi layoutu class, aby sa reklama spravne vykreslila
								dispatcher.fire(RENDER_BRANDING_EVENT, {
									isWide: false
								})

								elementForAdDisplay.classList.add(BRANDING_JSON_CLASSNAME)

								if (parsedData.type === BRANDING_JSON_TYPES.CODE) {
									if (advert.width === IFRAME_LAYOUT_WIDTH) {
										dispatcher.fire(RENDER_BRANDING_EVENT, {
											isIframe: true
										})
									}

									const sspAdvert = Object.assign({}, advert, {
										data: parsedData.creative
									})

									sspHelper.writeAd(sspAdvert, zone)

									elementForAdDisplay.classList.add(SspHelper.adFullClassName)

									// listener ktory je naviazany na video reklamu
									// pri spusteni pripadne zastaveni sa nastavuje animacia na stranke
									brandingRef.current &&
										$window.bindEventListener(
											brandingRef.current,
											SspHelper.SSP_CUSTOM_EVENTS.PAGE_OFFSET,
											(event) => {
												if (brandingSizer.current) {
													brandingSizer.current.style.height = event.detail + 'px'
												}
											}
										)
								} else {
									//send impress
									const { customImpress = '', impress = '' } = parsedData

									sspHelper.measureByImg(customImpress)
									sspHelper.measureByImg(impress)

									renderJsonBrandingTypeImageAndHtml(parsedData, advert, elementForAdDisplay)
								}
							} else if (
								advert.type === BRNADING_FORMATS.IFRAME ||
								advert.type === BRNADING_FORMATS.IFRAME_URL
							) {
								if (BRANDING_SMALL_WIDTH >= advert.width) {
									// custom event, ktory nastavi layoutu class, aby sa reklama spravne vykreslila
									dispatcher.fire(RENDER_BRANDING_EVENT, {
										isWide: true
									})

									if (brandingSizer.current) {
										brandingSizer.current.style.display = 'none'
									}
									if (brandingRef.current) {
										brandingRef.current.style.height = DEFAULT_BRANDING_HEIGHT + 'px'
										brandingRef.current.style.paddingTop = '8px'
									}
									elementForAdDisplay.classList.add(BRANDING_SUCCESS_CLASSNAME)

									sspHelper.writeAd(advert, zone)
								} else {
									dispatcher.fire(RENDER_BRANDING_EVENT, {
										isIframe: true
									})

									const data = sspHelper.sanitizeJsonAdvertData(advert.data)
									elementForAdDisplay.classList.add(BRANDING_IFRAME_CLASSNAME)
									elementForAdDisplay.style.marginLeft = -(advert.width / 2) + 'px'
									elementForAdDisplay.style.top = `${getRibbonHeight()}px`

									const sspAdvert = Object.assign({}, advert, {
										data
									})

									sspHelper.writeAd(sspAdvert, zone)
								}
							}
						} else {
							setIsAdEnabled(false)
						}
					}
				}
			])
		} else {
			setIsAdEnabled(false)
		}
	}

	const sendImpress = (advertData) => {
		const { tracking = {} } = advertData
		const { served = [] } = tracking

		served.forEach((url) => sspHelper.measureByImg(url))
	}

	// reklamu JSON type html5 a image vyreslujeme my
	const renderJsonBrandingTypeImageAndHtml = (jsonData, advertData, elementForAdDisplay) => {
		const { creative = '', clickthru = '', type = '' } = jsonData
		const { width, height } = advertData

		const renderElement = (outerElement, innerElement) => {
			innerElement.style.width = width + 'px'
			innerElement.style.height = height + 'px'
			innerElement.style.marginLeft = -(width / 2) + 'px'
			innerElement.style.top = `${getRibbonHeight()}px`

			outerElement.classList.add(`${CLASSNAME}__link`)
			innerElement.classList.add(`${CLASSNAME}__img`)

			outerElement.appendChild(innerElement)
			elementForAdDisplay.appendChild(outerElement)
		}

		const saveClickthru = xss(clickthru)

		// reklama typu html5 - vykresluje sa pomocou iframu
		if (type === BRANDING_JSON_TYPES.HTML) {
			// funkcie, ktora upravi src iframu kvoli spravnemu prekliku
			const createClickthruUrl = () => {
				if (creative) {
					return clickthru
						? `${creative}${creative.indexOf('?') === -1 ? '?' : '&'}clickthru=${encodeURIComponent(
								saveClickthru
						  )}`
						: creative
				} else {
					return ''
				}
			}

			const outerElement = document.createElement('div')
			const innerElement = document.createElement('iframe')

			innerElement.title = ID
			innerElement.src = createClickthruUrl()
			innerElement.name = createClickthruUrl()
			innerElement.width = width
			innerElement.height = height
			innerElement.marginHeight = 0
			innerElement.marginWidth = 0

			renderElement(outerElement, innerElement)
		} else if (type === BRANDING_JSON_TYPES.IMAGE) {
			const outerElement = document.createElement('a')
			const innerElement = document.createElement('img')

			outerElement.href = saveClickthru
			innerElement.alt = ''
			innerElement.src = creative

			renderElement(outerElement, innerElement)
		}
	}

	if (isSbrUser) return null

	return (
		<div
			className={classnames({
				[`${CLASSNAME}__wrap`]: true,
				[`${CLASSNAME}__wrap--ad-disabled`]: !isAdEnabled
			})}
		>
			<div className={`${CLASSNAME}__placeholder`}></div>
			<div className={CLASSNAME} ref={brandingRef}></div>
			<div ref={brandingSizer} className={`${CLASSNAME}__sizer`}></div>
		</div>
	)
}

export default Branding
