
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['Footer'] = {
  terms: function(d) { return "Smluvní podmínky"; },
  dataProtection: function(d) { return "Ochrana údajů"; },
  hint: function(d) { return "Nápověda"; },
  contact: function(d) { return "Kontakt"; },
  about: function(d) { return "O Seznamu"; },
  commercial: function(d) { return "Reklama"; },
  complaints: function(d) { return "Stížnosti"; },
  info: function(d) { return "© 1996–" + d.year + " Seznam.cz, a.s."; },
  importDocumentation: function(d) { return "Dokumentace importu"; },
  promoCebia: function(d) { return "Prověření Cebia"; },
  productList: function(d) { return "Produktový list"; },
  myAdverts: function(d) { return "Vstup pro prodejce"; },
  blog: function(d) { return "Blog Sauto.cz"; },
  consentManagementPlatform: function(d) { return "Nastavení personalizace"; },
  consentManagementPlatformError: function(d) { return "Nastavení personalizace se nepovedlo načíst, opakujte akci prosím později"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	