import AbstractComponent from 'app/base/AbstractComponent'
import React from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import UserwebAdvert from 'app/component/adverts/UserwebAdvertView'
import Paging from 'app/component/paging/PagingView'
import SortButton from 'app/component/sortButton/SortButtonView'
import SortConstants, { OperatingLeaseSortConstants } from 'app/component/sortButton/SortConstants'
import Square, { RICH_MEDIA_COLLOCATION } from 'app/component/sspAdverts/square/SquareView'
import Native from 'app/component/sspAdverts/native/NativeView'
import MiddleRectangle from 'app/component/sspAdverts/middleRectangle/MiddleRectangle'
import { PaginationHelper } from '@inzeraty/models'
import ROUTE_NAMES from 'app/base/RouteNames'
import select from 'ima-plugin-select'
import STATE_KEYS from 'app/page/userweb/advertList/AdvertListStateKeys'
import { DefaultProps as DEFAULT_PROPS, Format } from '@inzeraty/helpers'
import AdvertListExtension from 'app/component/advertList/AdvertListExtension'
import UrlConvertor from 'app/helpers/urlConvertor/UrlConvertor'
import { Surface } from '@sznds/react'
import Filters from 'app/component/filters/FiltersView'
import { DATA_SEOT } from 'app/base/SeoTesting'
import EmptyList from 'app/component/emptyList/EmptyList'
import { stickyHOC } from '@inzeraty/components'
import PreferredList from './preferredList/PreferredList'
import advertPropTypes from 'app/model/advert/AdvertPropTypes'
import { CLICK_FROM_VALUES } from './AdvertListConstants'
import { addDetailInfoToLocalStorage } from './AdvertListHelper'
import { getSearchTypeSession } from 'app/helpers/sessionStorageHelpers/getSearchTypeSessionHelper'

import './AdvertListView.less'

export const CLASSNAME = 'c-item-list'
const MINIMAL_ADVERTS_COUNT_FOR_SSP = 8 // Minimální počet inzerátů na stránku pro získání/zobrazení reklamy.
const MINIMAL_ADVERTS_COUNT_FOR_NATIVE = 15 // Minimální počet inzerátů na stránku pro zobrazeni nativni reklamy.
const MINIMAL_ADVERTS_COUNT_FOR_SECOND_NATIVE = 20 // Minimalni pocet inzeratu na stranku pro zobrazeni druhe nativni reklamy.

class AdvertListView extends AbstractComponent {
	static get propTypes() {
		return {
			isOperatingLease: PropTypes.bool,
			advertListAndPagination: PropTypes.object,
			advertListPlaceholders: PropTypes.array,
			prevRouteName: PropTypes.string,
			showFilterButton: PropTypes.bool,
			renderHeader: PropTypes.func,
			renderSubHeader: PropTypes.func,
			renderSubSubHeader: PropTypes.func,
			renderNoResults: PropTypes.func,
			urlPageCreator: PropTypes.func.isRequired,
			showNextOnLastPage: PropTypes.bool,
			prevText: PropTypes.string,
			nextText: PropTypes.string,
			preferredList: PropTypes.arrayOf(PropTypes.instanceOf(advertPropTypes)),
			isList: PropTypes.bool,
			showSortTooltip: PropTypes.bool
		}
	}

	static get defaultProps() {
		return {
			isOperatingLease: false,
			advertListAndPagination: DEFAULT_PROPS.OBJECT,
			advertListPlaceholders: DEFAULT_PROPS.ARRAY,
			prevRouteName: '',
			showFilterButton: true,
			renderSubHeader: DEFAULT_PROPS.FUNCTION,
			renderSubSubHeader: DEFAULT_PROPS.FUNCTION,
			preferredList: DEFAULT_PROPS.ARRAY,
			isList: false,
			showSortTooltip: false
		}
	}

	constructor(props, context) {
		super(props, context)

		this._renderAdvertsCount = this._renderAdvertsCount.bind(this)
		this._renderSortButton = this._renderSortButton.bind(this)
	}

	_renderFilters() {
		const { showFilterButton } = this.props

		const StickyHOC = stickyHOC(() => (
			<div className={`${CLASSNAME}__show-all-filters-button`}>
				<Filters isOnHP={false} />
			</div>
		))

		return (
			<>
				{showFilterButton && (
					<div className={`${CLASSNAME}__show-all-filters-button-wrap`}>
						<StickyHOC />
					</div>
				)}
			</>
		)
	}

	render() {
		const {
			advertListAndPagination,
			listRef,
			renderHeader,
			renderSubHeader,
			renderSubSubHeader
		} = this.props
		const { paginationEntity = {} } = advertListAndPagination
		const { advertList } = advertListAndPagination
		const isEmpty = advertList && advertList.length === 0

		const header = (
			<div className={`${CLASSNAME}__sort-count-wrapper`}>
				{renderHeader ? (
					renderHeader({
						renderAdvertsCount: this._renderAdvertsCount,
						renderSortButton: this._renderSortButton
					})
				) : (
					<>
						{this._renderAdvertsCount()}
						{this._renderSortButton()}
					</>
				)}
			</div>
		)

		if (isEmpty) {
			return (
				<>
					{this._renderFilters()}
					<div ref={listRef} className={`${CLASSNAME}__no-results`}>
						{header}
						{this._renderNoResults()}
					</div>
				</>
			)
		} else {
			return (
				<>
					{this._renderFilters()}
					<div className={CLASSNAME}>
						{header}
						{renderSubHeader()}
						{renderSubSubHeader()}
						{this._renderAdverts()}
						{this._renderPagination(paginationEntity)}
					</div>
				</>
			)
		}
	}

	_renderAdvertsCount() {
		const { advertListAndPagination, isList } = this.props
		const { paginationEntity = {} } = advertListAndPagination
		const { total } = paginationEntity

		const isPlaceholder = typeof total === 'undefined'

		return (
			<div
				className={this.cssClasses({
					[`${CLASSNAME}__count`]: true,
					[`${CLASSNAME}__count--plh`]: isPlaceholder,
					[`${CLASSNAME}__count--list`]: isList
				})}
				data-seot={DATA_SEOT.ADVERTS_COUNT}
			>
				{!isPlaceholder &&
					this.localize('AdvertList.advertsCounter', {
						COUNT: Format.number(total)
					})}
			</div>
		)
	}

	/**
	 * Vyrenderuje tlacitko razeni inzeratu
	 *
	 * @method _renderSortButton
	 * @private
	 * @return {HTMLElement}
	 */
	_renderSortButton() {
		const { isOperatingLease, showSortTooltip } = this.props
		const {
			params: { [UrlConvertor.constants.URL_APP_PARAMS.SORT]: advertListSortParam }
		} = this.utils.$Router.getCurrentRouteInfo()

		return (
			<SortButton
				advertListSortParam={advertListSortParam}
				className={`${CLASSNAME}__sort-btn`}
				sortConstants={isOperatingLease ? OperatingLeaseSortConstants : SortConstants}
				showTooltip={showSortTooltip}
			/>
		)
	}

	_renderAdverts() {
		const {
			advertListAndPagination,
			advertListPlaceholders,
			listRef,
			preferredList,
			isOperatingLease
		} = this.props

		const { advertList, paginationEntity = {} } = advertListAndPagination
		const advertListToRender = !advertList ? advertListPlaceholders : advertList

		const { DotAnalytic, $SessionStorage } = this.utils

		const { lastUrlRouteParams, ...searchTypeSession } = getSearchTypeSession($SessionStorage) || {}

		const handleAdvertClick = (advertEntity) => {
			DotAnalytic.hit({
				action: 'list-to-advert-detail',
				...searchTypeSession,
				categoryId: advertEntity?.category?.id
			})
			addDetailInfoToLocalStorage(advertEntity.id, CLICK_FROM_VALUES.FROM_LIST)
		}

		return (
			<ul ref={listRef} className={`${CLASSNAME}__list`}>
				{advertListToRender.map((advertEntity, index) => (
					<UserwebAdvert
						onHandle={() => handleAdvertClick(advertEntity)}
						isOperatingLease={isOperatingLease}
						advertEntity={advertEntity}
						key={advertEntity.id}
						index={index}
						imageLazyload={index > 1}
					/>
				))}
				{!isOperatingLease && (
					<PreferredList
						className={`${CLASSNAME}__preferred`}
						preferredList={preferredList}
						tagName='li'
						imageLazyload={true}
					/>
				)}
				{this._renderSspAdvert(paginationEntity)}
				{this._renderNativeAdvert(paginationEntity)}
				{this._renderSecondNativeAdvert(paginationEntity)}
			</ul>
		)
	}

	_renderNoResults() {
		const {
			advertListAndPagination,
			prevRouteName,
			renderNoResults: overrideDefaultNoResults
		} = this.props
		const { advertList } = advertListAndPagination
		const homepageLink = this.link(ROUTE_NAMES.USERWEB.HOMEPAGE)

		const renderLink = () => {
			if (prevRouteName) {
				return (
					<button onClick={() => history.back()} className={`${CLASSNAME}__link`}>
						{this.localize('AdvertList.noResultsLink')}
					</button>
				)
			} else {
				return (
					<a href={homepageLink} className={`${CLASSNAME}__link`}>
						{this.localize('AdvertList.noResultsHP')}
					</a>
				)
			}
		}

		if (advertList && advertList.length === 0) {
			const renderDefaultNoResults = () => <EmptyList renderLink={renderLink} />

			return overrideDefaultNoResults
				? overrideDefaultNoResults({ renderDefaultNoResults })
				: renderDefaultNoResults()
		}
	}

	_renderSspAdvert(paginationEntity) {
		const itemsCount = PaginationHelper.getItemsCount(paginationEntity)

		if (itemsCount >= MINIMAL_ADVERTS_COUNT_FOR_SSP) {
			return (
				<li className={`${CLASSNAME}__ssp`}>
					<Square
						id='ssp-square-item-list'
						className={`${CLASSNAME}__square`}
						height={640}
						extraCollocation={RICH_MEDIA_COLLOCATION}
					/>
					<MiddleRectangle
						className={`${CLASSNAME}__rect`}
						elementId='middleRectangle'
						zoneId={214021}
						width={690}
						height={200}
					/>
				</li>
			)
		}
	}

	_renderNativeAdvert(paginationEntity) {
		const itemsCount = PaginationHelper.getItemsCount(paginationEntity)

		if (itemsCount >= MINIMAL_ADVERTS_COUNT_FOR_NATIVE) {
			return (
				<li className={`${CLASSNAME}__native`}>
					<Surface tagName='div' surface={5}>
						<Native id='seznam.sauto.ks.inzerat' />
					</Surface>
				</li>
			)
		}
	}

	_renderSecondNativeAdvert(paginationEntity) {
		const itemsCount = PaginationHelper.getItemsCount(paginationEntity)

		if (itemsCount >= MINIMAL_ADVERTS_COUNT_FOR_SECOND_NATIVE) {
			// druhou nativni reklamu chceme zobrazovat jenom na mobilu a chceme ji
			// zacit nacitat s rozumnym predstihem, at je reklama nactena, nez k ni
			// uzivatel doscrolluje. Zobrazeni jenom pro mobil je reseno pomoci CSS,
			// pokud obalovaci element neni videt na desktopu, tak inView bude vzdy
			// false.
			return (
				<li className={`${CLASSNAME}__native-second`}>
					<InView rootMargin='2000px 0px 2000px 0px' triggerOnce={true}>
						{({ inView, ref }) => (
							<div ref={ref}>
								{inView && (
									<Surface tagName='div' surface={5}>
										<Native id='seznam.sauto.ks.inzerat' />
									</Surface>
								)}
							</div>
						)}
					</InView>
				</li>
			)
		}
	}

	_renderPagination(paginationEntity) {
		if (paginationEntity) {
			const { urlPageCreator, showNextOnLastPage, prevText, nextText } = this.props

			return (
				<Paging
					paginationEntity={paginationEntity}
					urlPageCreator={urlPageCreator}
					showNextOnLastPage={showNextOnLastPage}
					prevText={prevText}
					nextText={nextText}
				/>
			)
		}
	}
}

const AdvertListSelector = (state) => ({
	advertListAndPagination: state[AdvertListExtension.stateIds.ADVERT_LIST_PAGINATION],
	advertListPlaceholders: state[AdvertListExtension.stateIds.ADVERT_LIST_PLACEHOLDERS],
	prevRouteName: state[STATE_KEYS.PREV_ROUTE_NAME],
	preferredList: state[AdvertListExtension.stateIds.ADVERT_LIST_PREFERRED]
})

export default select(AdvertListSelector)(
	React.forwardRef((props, ref) => <AdvertListView {...props} ref={ref} />)
)
