/**
 * Identifikátor služby sauto.cz
 * @constant
 * @type {String}
 */
const SERVICE = 'sauto'

/**
 * Použitelné fitry SDNky
 * @const
 */
const SDN_FILTER = {
	BASE: '?fl=', // Parametr URL pro filtry u SDNky
	WATERMARK: 'wrm,/watermark/sauto.png,10,10',
	RESOLUTION: 'res', // Nastavení rozlišení
	JPG_SETTING: 'jpg,80,,1', // Nastavení jpg (kvalita 80%, progresivní)
	WEBP_SETTINGS: 'webp,75', // Nastavení webp (kvalita 75%)
	EXIF: 'exf', // Otočení dle EXIFu
	CROP_BY_RATIO: 'crr,1.33333,0' // Ořez obrázku na daný poměr stran (jen na šířku)
}

/**
 * Vrátí správnou podobu parametrů pro stažení obrázku z SDNky.
 *
 * @param {String}  options.width       Šířka obrázku
 * @param {String}  options.height      Výška obrázku
 * @param {Boolean} options.isWatermark Zobrazit watermark
 * @param {Boolean} options.isCropped   Oříznout na základě poměru stran
 * @return {String} Parametry pro url obrázku
 */
const _getSdnParams = ({
	width,
	height,
	aspcectRatio = '1',
	isWatermark = true,
	isCropped = false,
	isWebP = false
}) => {
	let prms = SDN_FILTER.BASE
	prms += SDN_FILTER.EXIF
	prms += isCropped ? `|${SDN_FILTER.CROP_BY_RATIO}` : ''
	prms += `|${SDN_FILTER.RESOLUTION},${width},${height},${aspcectRatio}`
	prms += isWatermark ? `|${SDN_FILTER.WATERMARK}` : ''
	prms += isWebP ? `|${SDN_FILTER.WEBP_SETTINGS}` : `|${SDN_FILTER.JPG_SETTING}`

	return prms
}

/**
 * Přehled rozlišení domluvené s SDNkou - nasteveni pro sauto
 */
const SDN = {
	w320x240: _getSdnParams({ width: '320', height: '240', isWatermark: false }),

	w320_WATERMARK: _getSdnParams({ width: '320', height: '240' }),
	w640_WATERMARK: _getSdnParams({ width: '640', height: '480' }),
	w800_WATERMARK: _getSdnParams({ width: '800', height: '600' }),
	w1024_WATERMARK: _getSdnParams({ width: '1024', height: '768' }),
	w1920_WATERMARK: _getSdnParams({ width: '1920', height: '1440' }),

	w320_WATERMARK_CROP: _getSdnParams({ width: '320', height: '240', isCropped: true }),
	w640_WATERMARK_CROP: _getSdnParams({ width: '640', height: '480', isCropped: true }),
	w800_WATERMARK_CROP: _getSdnParams({ width: '800', height: '600', isCropped: true }),
	w1024_WATERMARK_CROP: _getSdnParams({ width: '1024', height: '768', isCropped: true }),
	w1920_WATERMARK_CROP: _getSdnParams({ width: '1920', height: '1440', isCropped: true }),

	w40: _getSdnParams({ width: '40', height: '40', aspcectRatio: '3', isWatermark: false }),
	w96: _getSdnParams({ width: '96', height: '80', aspcectRatio: '3', isWatermark: false }),
	w112: _getSdnParams({ width: '112', height: '128', aspcectRatio: '3', isWatermark: false }),
	w200: _getSdnParams({ width: '200', height: '150', aspcectRatio: '3', isWatermark: false }),
	w320: _getSdnParams({ width: '320', height: '208', aspcectRatio: '3', isWatermark: false }),

	w96_WEBP: _getSdnParams({
		width: '96',
		height: '80',
		aspcectRatio: '3',
		isWatermark: false,
		isWebP: true
	}),

	w267: _getSdnParams({ width: '267', height: '200', aspcectRatio: '3', isWatermark: false }),
	w360: _getSdnParams({ width: '360', height: '270', aspcectRatio: '3', isWatermark: false }),

	w360_WEBP: _getSdnParams({
		width: '360',
		height: '270',
		aspcectRatio: '3',
		isWatermark: false,
		isWebP: true
	}),

	w124h89: `${SDN_FILTER.BASE}${SDN_FILTER.RESOLUTION},124,89,3`
}

/**
 * Breakpointy
 */
const RESPONSIVE = {
	MOBILE_MEDIUM: 375,
	MOBILE_MAX: 511,
	MOBILE_WIDE: 512,
	MOBILE_WIDE_MAX: 767,
	TABLET: 768,
	DESKTOP: 1016,
	DESKTOP_WIDE: 1088,
	DESKTOP_BRANDING: 990,
	DESKTOP_BRANDING_RAM: 1366
}

/**
 * Prevodnik z kilowattu na kone (metrickou konskou silu)
 */
const toMetricHorsePowerConversionRate = 1.35962

/**
 * Seznam Id podkategorii, pro ktere se v nadpisu vypisu a tlacitka zpet z detailu
 * nema zobrazovat znacka a model, ale jen hlavni kategorie a znacka
 */
const exceptionalHeaderModelIds = [1092 /* Ostatni/Ostatni */]

/**
 * Hlavní kategorie.
 */
const CATEGORIES = {
	PASSENGER_CARS: {
		// Osobní'
		sspSection: 'osobni',
		seoName: 'osobni',
		name: 'Osobní',
		id: 838,
		svgIcon: 'CATEGORY_OSOBNI'
	},
	UTILITY_CARS: {
		// Užitková
		sspSection: 'uzitkova',
		seoName: 'uzitkova',
		name: 'Užitková',
		id: 839,
		svgIcon: 'CATEGORY_UZITKOVE'
	},
	TRUCKS: {
		// Nákladní
		sspSection: 'nakladni',
		seoName: 'nakladni',
		name: 'Nákladní',
		id: 840,
		svgIcon: 'CATEGORY_NAKLADNI'
	},
	MOTORCYCLE: {
		// Motorky
		sspSection: 'motorky',
		seoName: 'motorky',
		name: 'Motorky',
		id: 841,
		svgIcon: 'CATEGORY_MOTORKY'
	},
	QUAD: {
		// Čtyřkolky
		sspSection: 'ctyrkolky',
		seoName: 'ctyrkolky',
		name: 'Čtyřkolky',
		id: 842,
		svgIcon: 'CATEGORY_CTYRKOLKY'
	},
	TRAILERS: {
		// Přívěsy a návěsy.
		sspSection: 'privesy',
		seoName: 'privesy-navesy',
		name: 'Přívěsy a návěsy',
		id: 843,
		svgIcon: 'CATEGORY_PRIVESY'
	},
	CAMPER: {
		// Obytné
		sspSection: 'obytne',
		seoName: 'obytne',
		name: 'Obytné',
		id: 844,
		svgIcon: 'CATEGORY_OBYTNE'
	},
	WORKING_MACHINES: {
		// Pracovní stroje
		sspSection: 'pracovni',
		seoName: 'pracovni-stroje',
		name: 'Pracovní stroje',
		id: 845,
		svgIcon: 'CATEGORY_PRACOVNI'
	},
	BUSES: {
		// Autobusy
		sspSection: 'autobusy',
		seoName: 'autobusy',
		name: 'Autobusy',
		id: 846,
		svgIcon: 'CATEGORY_AUTOBUSY'
	}
}

const CAR_BODY = {
	CUV: {
		seoName: 'cuv',
		svgIcon: 'CAR_BODY_CUV'
	},
	ROADSTER: {
		seoName: 'roadster',
		svgIcon: 'CAR_BODY_ROADSTER'
	},
	VAN: {
		seoName: 'van',
		svgIcon: 'CAR_BODY_VAN'
	},
	OFF_ROAD: {
		seoName: 'terenni',
		svgIcon: 'CAR_BODY_TERENNI'
	},
	PICK_UP: {
		seoName: 'pick-up',
		svgIcon: 'CAR_BODY_PICKUP'
	},
	LIFTBACK: {
		seoName: 'liftback',
		svgIcon: 'CAR_BODY_LIFTBACK'
	},
	MPV: {
		seoName: 'mpv',
		svgIcon: 'CAR_BODY_MPV'
	},
	CUPE: {
		seoName: 'kupe',
		svgIcon: 'CAR_BODY_KUPE'
	},
	HATCHBACK: {
		seoName: 'hatchback',
		svgIcon: 'CAR_BODY_HATCHBACK'
	},
	CABRIOLET: {
		seoName: 'kabriolet',
		svgIcon: 'CAR_BODY_KABRIOLET'
	},
	SEDANLIMUZINA: {
		seoName: 'sedanlimuzina',
		svgIcon: 'CAR_BODY_SEDANLIMUZINA'
	},
	SUV: {
		seoName: 'suv',
		svgIcon: 'CAR_BODY_SUV'
	},
	COMBI: {
		seoName: 'kombi',
		svgIcon: 'CAR_BODY_KOMBI'
	}
}

const ELECTRIC_VEHICLE_FUEL_OPTION_NAME = 'Elektro'

// termin dodani
const AVAILABILITY = {
	// presne datum
	DATE: {
		value: 1
	},
	// skladem
	IN_STOCK: {
		value: 2
	},
	// na objednavku
	ORDER: {
		value: 3
	}
}

const CAR_CONDITION = {
	NEW: {
		value: 1
	},
	USED: {
		value: 2
	},
	PRESENTATION: {
		value: 4
	},
	CRASCHED: {
		value: 3
	},
	CLASSIC_CAR: {
		value: 5
	}
}

const UPLOADER_CONSTANTS = {
	SIZE: {
		PHOTO_MIN_WIDTH: 1024,
		PHOTO_MIN_HEIGHT: 550,
		PHOTO_MAX_WIDTH: 2048,
		PHOTO_MAX_HEIGHT: 2048
	},
	DATA_LIMIT: {
		VIDEO_MAX_DATA: 1073741824
	},
	ERROR: {
		TOO_SMALL: 'too_small',
		DATA_TOO_BIG: 'data_too_big',
		ADVERT_HAS_VIDEO: 'advert_has_video',
		BACKEND_ERROR: 'backend_error',
		REMOVE_ERROR: 'remove_error'
	}
}

const INTERNAL_ADMIN = {
	DEFAULT_PER_PAGE: 10,
	PER_PAGE_OPTIONS: [10, 20, 30],
	ADVERTS_PER_PAGE_OPTIONS: [10, 20, 30, 50, 100]
}

/**
 * Seznam možných práv adminů.
 */
const ADMIN_RIGHTS = {
	ADMINS_VIEW: 'admins_view',
	ADMINS_CREATE: 'admins_create',
	ADMINS_EDIT: 'admins_edit',
	ADMINS_DELETE: 'admins_delete',
	PREMISE_VIEW: 'premises_view',
	PREMISE_EDIT: 'premises_edit',
	PREMISE_IMPORT_VIEW: 'premises_import_logs',
	PREMISE_DELETE: 'premises_delete',
	ADVERTS_VIEW: 'adverts_view',
	ADVERTS_EDIT: 'adverts_edit',
	ADVERTS_VIN_DUPLICATION_FRAUD_EDIT: 'adverts_fraudulent_edit',
	ADVERTS_REPORTS: 'adverts_reports_view_all',
	ADVERTS_REPORTS_EDIT: 'adverts_reports_edit',
	ROLES_VIEW: 'roles_view',
	ROLES_CREATE: 'roles_create',
	ROLES_EDIT: 'roles_edit',
	ROLES_DELETE: 'roles_delete',
	ADVERT_STATISTICS_VIEW: 'advert_statistics_view',
	REGISTRATIONS_VIEW: 'registrations_view',
	REGISTRATIONS_CREATE: 'registrations_create',
	REGISTRATIONS_EDIT: 'registrations_edit',
	REGISTRATIONS_DELETE: 'registrations_delete',
	USERS_VIEW: 'users_view',
	USERS_EDIT: 'users_edit',
	USERS_DELETE: 'users_delete',
	PREFERRED_OFFERS_VIEW: 'preferred_offers_view',
	REVIEWS_VIEW: 'reviews_view',
	REVIEWS_EDIT: 'reviews_edit',
	REVIEWS_DELETE: 'reviews_delete',
	CODEBOOK_VALUES_CREATE: 'codebook_values_create',
	CODEBOOK_VALUES_EDIT: 'codebook_values_edit',
	CODEBOOK_VALUES_DELETE: 'codebook_values_delete',
	PREMISE_EDIT_USE_ADVERT_LOCALITY: 'premise_edit_use_advert_locality',
	SERVICE_FEEDBACK_VIEW: 'service_feedback_view',
	SERVICE_FEEDBACK_DELETE: 'service_feedback_delete',
	ARTICLES_VIEW: 'articles_view',
	ARTICLES_EDIT: 'articles_edit',
	MIRRORING_VIEW: 'adverts_mirroring_view'
}

const COLOR_CODES = Object.freeze({
	1: '#ffffff', // Bila
	2: '#f8e71c', // Zluta
	3: '#ffae00', // Oranzova
	4: '#cc0000', // Cervena
	5: '#a52727', // Vinova
	6: '#e010bb', // Ruzova
	7: '#8303f3', // Fialova
	8: '#0645ad', // Modra
	9: '#1eae00', // Zelena
	10: '#8b572a', // Hneda
	11: '#808080', // Seda
	12: '#000000', // Cerna
	13: '#e5dcbe', // Bezova
	14: '#e0e0e0', // Stribrna
	15: '#e0c113', // Zlata
	16: '#f8f8f8', // Jina
	17: '#c57b4a' // Bronzova
})

/**
 * V postatě kopie dat z resourcu /api/v1/regions
 */
const REGIONS = {
	JIHOCESKY: {
		id: 1,
		name: 'Jihočeský kraj',
		seoName: 'jihocesky-kraj'
	},
	PLZENSKY: {
		id: 2,
		name: 'Plzeňský kraj',
		seoName: 'plzensky-kraj'
	},
	KARLOVARSKY: {
		id: 3,
		name: 'Karlovarský kraj',
		seoName: 'karlovarsky-kraj'
	},
	USTECKY: {
		id: 4,
		name: 'Ústecký kraj',
		seoName: 'ustecky-kraj'
	},
	LIBERECKY: {
		id: 5,
		name: 'Liberecký kraj',
		seoName: 'liberecky-kraj'
	},
	KRALOVEHRADECKY: {
		id: 6,
		name: 'Královéhradecký kraj',
		seoName: 'kralovehradecky-kraj'
	},
	PARDUBICKY: {
		id: 7,
		name: 'Pardubický kraj',
		seoName: 'pardubicky-kraj'
	},
	OLOMOUCKY: {
		id: 8,
		name: 'Olomoucký kraj',
		seoName: 'olomoucky-kraj'
	},
	ZLINSKY: {
		id: 9,
		name: 'Zlínský kraj',
		seoName: 'zlinsky-kraj'
	},
	PRAHA: {
		id: 10,
		name: 'Hlavní město Praha',
		seoName: 'hlavni-mesto-praha'
	},
	STREDOCESKY: {
		id: 11,
		name: 'Středočeský kraj',
		seoName: 'stredocesky-kraj'
	},
	MORAVSKOSLEZSKY: {
		id: 12,
		name: 'Moravskoslezský kraj',
		seoName: 'moravskoslezsky-kraj'
	},
	VYSOCINA: {
		id: 13,
		name: 'Kraj Vysočina',
		seoName: 'kraj-vysocina'
	},
	JIHOMORAVSKY: {
		id: 14,
		name: 'Jihomoravský kraj',
		seoName: 'jihomoravsky-kraj'
	}
}

const DISTRICTS = {
	CESKE_BUDEJOVICE: {
		id: 1,
		name: 'České Budějovice',
		seoName: 'ceske-budejovice',
		region: REGIONS.JIHOCESKY
	},
	CESKY_KRUMLOV: {
		id: 2,
		name: 'Český Krumlov',
		seoName: 'cesky-krumlov',
		region: REGIONS.JIHOCESKY
	},
	JINDRICHUV_HRADEC: {
		id: 3,
		name: 'Jindřichův Hradec',
		seoName: 'jindrichuv-hradec',
		region: REGIONS.JIHOCESKY
	},
	PISEK: {
		id: 4,
		name: 'Písek',
		seoName: 'pisek',
		region: REGIONS.JIHOCESKY
	},
	PRACHATICE: {
		id: 5,
		name: 'Prachatice',
		seoName: 'prachatice',
		region: REGIONS.JIHOCESKY
	},
	STRAKONICE: {
		id: 6,
		name: 'Strakonice',
		seoName: 'strakonice',
		region: REGIONS.JIHOCESKY
	},
	TABOR: {
		id: 7,
		name: 'Tábor',
		seoName: 'tabor',
		region: REGIONS.JIHOCESKY
	},
	DOMAZLICE: {
		id: 8,
		name: 'Domažlice',
		seoName: 'domazlice',
		region: REGIONS.PLZENSKY
	},
	KLATOVY: {
		id: 11,
		name: 'Klatovy',
		seoName: 'klatovy',
		region: REGIONS.PLZENSKY
	},
	PLZEN_MESTO: {
		id: 12,
		name: 'Plzeň-město',
		seoName: 'plzen-mesto',
		region: REGIONS.PLZENSKY
	},
	PLZEN_JIH: {
		id: 13,
		name: 'Plzeň-jih',
		seoName: 'plzen-jih',
		region: REGIONS.PLZENSKY
	},
	PLZEN_SEVER: {
		id: 14,
		name: 'Plzeň-sever',
		seoName: 'plzen-sever',
		region: REGIONS.PLZENSKY
	},
	ROKYCANY: {
		id: 15,
		name: 'Rokycany',
		seoName: 'rokycany',
		region: REGIONS.PLZENSKY
	},
	TACHOV: {
		id: 17,
		name: 'Tachov',
		seoName: 'tachov',
		region: REGIONS.PLZENSKY
	},
	CHEB: {
		id: 9,
		name: 'Cheb',
		seoName: 'cheb',
		region: REGIONS.KARLOVARSKY
	},
	KARLOVY_VARY: {
		id: 10,
		name: 'Karlovy Vary',
		seoName: 'karlovy-vary',
		region: REGIONS.KARLOVARSKY
	},
	SOKOLOV: {
		id: 16,
		name: 'Sokolov',
		seoName: 'sokolov',
		region: REGIONS.KARLOVARSKY
	},
	DECIN: {
		id: 19,
		name: 'Děčín',
		seoName: 'decin',
		region: REGIONS.USTECKY
	},
	CHOMUTOV: {
		id: 20,
		name: 'Chomutov',
		seoName: 'chomutov',
		region: REGIONS.USTECKY
	},
	LITOMERICE: {
		id: 23,
		name: 'Litoměřice',
		seoName: 'litomerice',
		region: REGIONS.USTECKY
	},
	LOUNY: {
		id: 24,
		name: 'Louny',
		seoName: 'louny',
		region: REGIONS.USTECKY
	},
	MOST: {
		id: 25,
		name: 'Most',
		seoName: 'most',
		region: REGIONS.USTECKY
	},
	TEPLICE: {
		id: 26,
		name: 'Teplice',
		seoName: 'teplice',
		region: REGIONS.USTECKY
	},
	'USTI_NAD-LABEM': {
		id: 27,
		name: 'Ústí nad Labem',
		seoName: 'usti-nad-labem',
		region: REGIONS.USTECKY
	},
	CESKA_LIPA: {
		id: 18,
		name: 'Česká Lípa',
		seoName: 'ceska-lipa',
		region: REGIONS.LIBERECKY
	},
	'JABLONEC_NAD-NISOU': {
		id: 21,
		name: 'Jablonec nad Nisou',
		seoName: 'jablonec-nad-nisou',
		region: REGIONS.LIBERECKY
	},
	LIBEREC: {
		id: 22,
		name: 'Liberec',
		seoName: 'liberec',
		region: REGIONS.LIBERECKY
	},
	SEMILY: {
		id: 34,
		name: 'Semily',
		seoName: 'semily',
		region: REGIONS.LIBERECKY
	},
	HRADEC_KRALOVE: {
		id: 28,
		name: 'Hradec Králové',
		seoName: 'hradec-kralove',
		region: REGIONS.KRALOVEHRADECKY
	},
	JICIN: {
		id: 30,
		name: 'Jičín',
		seoName: 'jicin',
		region: REGIONS.KRALOVEHRADECKY
	},
	NACHOD: {
		id: 31,
		name: 'Náchod',
		seoName: 'nachod',
		region: REGIONS.KRALOVEHRADECKY
	},
	'RYCHNOV_NAD-KNEZNOU': {
		id: 33,
		name: 'Rychnov nad Kněžnou',
		seoName: 'rychnov-nad-kneznou',
		region: REGIONS.KRALOVEHRADECKY
	},
	TRUTNOV: {
		id: 36,
		name: 'Trutnov',
		seoName: 'trutnov',
		region: REGIONS.KRALOVEHRADECKY
	},
	CHRUDIM: {
		id: 29,
		name: 'Chrudim',
		seoName: 'chrudim',
		region: REGIONS.PARDUBICKY
	},
	PARDUBICE: {
		id: 32,
		name: 'Pardubice',
		seoName: 'pardubice',
		region: REGIONS.PARDUBICKY
	},
	SVITAVY: {
		id: 35,
		name: 'Svitavy',
		seoName: 'svitavy',
		region: REGIONS.PARDUBICKY
	},
	'USTI_NAD-ORLICI': {
		id: 37,
		name: 'Ústí nad Orlicí',
		seoName: 'usti-nad-orlici',
		region: REGIONS.PARDUBICKY
	},
	PROSTEJOV: {
		id: 40,
		name: 'Prostějov',
		seoName: 'prostejov',
		region: REGIONS.OLOMOUCKY
	},
	OLOMOUC: {
		id: 42,
		name: 'Olomouc',
		seoName: 'olomouc',
		region: REGIONS.OLOMOUCKY
	},
	PREROV: {
		id: 43,
		name: 'Přerov',
		seoName: 'prerov',
		region: REGIONS.OLOMOUCKY
	},
	SUMPERK: {
		id: 44,
		name: 'Šumperk',
		seoName: 'sumperk',
		region: REGIONS.OLOMOUCKY
	},
	JESENIK: {
		id: 46,
		name: 'Jeseník',
		seoName: 'jesenik',
		region: REGIONS.OLOMOUCKY
	},
	ZLIN: {
		id: 38,
		name: 'Zlín',
		seoName: 'zlin',
		region: REGIONS.ZLINSKY
	},
	KROMERIZ: {
		id: 39,
		name: 'Kroměříž',
		seoName: 'kromeriz',
		region: REGIONS.ZLINSKY
	},
	UHERSKE_HRADISTE: {
		id: 41,
		name: 'Uherské Hradiště',
		seoName: 'uherske-hradiste',
		region: REGIONS.ZLINSKY
	},
	VSETIN: {
		id: 45,
		name: 'Vsetín',
		seoName: 'vsetin',
		region: REGIONS.ZLINSKY
	},
	'HLAVNI_MESTO-PRAHA': {
		id: 47,
		name: 'Hlavní město Praha',
		seoName: 'hlavni-mesto-praha',
		region: REGIONS.PRAHA
	},
	BENESOV: {
		id: 48,
		name: 'Benešov',
		seoName: 'benesov',
		region: REGIONS.STREDOCESKY
	},
	BEROUN: {
		id: 49,
		name: 'Beroun',
		seoName: 'beroun',
		region: REGIONS.STREDOCESKY
	},
	KLADNO: {
		id: 50,
		name: 'Kladno',
		seoName: 'kladno',
		region: REGIONS.STREDOCESKY
	},
	KOLIN: {
		id: 51,
		name: 'Kolín',
		seoName: 'kolin',
		region: REGIONS.STREDOCESKY
	},
	KUTNA_HORA: {
		id: 52,
		name: 'Kutná Hora',
		seoName: 'kutna-hora',
		region: REGIONS.STREDOCESKY
	},
	MLADA_BOLESLAV: {
		id: 53,
		name: 'Mladá Boleslav',
		seoName: 'mlada-boleslav',
		region: REGIONS.STREDOCESKY
	},
	MELNIK: {
		id: 54,
		name: 'Mělník',
		seoName: 'melnik',
		region: REGIONS.STREDOCESKY
	},
	NYMBURK: {
		id: 55,
		name: 'Nymburk',
		seoName: 'nymburk',
		region: REGIONS.STREDOCESKY
	},
	PRAHA_VYCHOD: {
		id: 56,
		name: 'Praha-východ',
		seoName: 'praha-vychod',
		region: REGIONS.STREDOCESKY
	},
	PRAHA_ZAPAD: {
		id: 57,
		name: 'Praha-západ',
		seoName: 'praha-zapad',
		region: REGIONS.STREDOCESKY
	},
	PRIBRAM: {
		id: 58,
		name: 'Příbram',
		seoName: 'pribram',
		region: REGIONS.STREDOCESKY
	},
	RAKOVNIK: {
		id: 59,
		name: 'Rakovník',
		seoName: 'rakovnik',
		region: REGIONS.STREDOCESKY
	},
	BRUNTAL: {
		id: 60,
		name: 'Bruntál',
		seoName: 'bruntal',
		region: REGIONS.MORAVSKOSLEZSKY
	},
	FRYDEK_MISTEK: {
		id: 61,
		name: 'Frýdek-Místek',
		seoName: 'frydek-mistek',
		region: REGIONS.MORAVSKOSLEZSKY
	},
	KARVINA: {
		id: 62,
		name: 'Karviná',
		seoName: 'karvina',
		region: REGIONS.MORAVSKOSLEZSKY
	},
	NOVY_JICIN: {
		id: 63,
		name: 'Nový Jičín',
		seoName: 'novy-jicin',
		region: REGIONS.MORAVSKOSLEZSKY
	},
	OPAVA: {
		id: 64,
		name: 'Opava',
		seoName: 'opava',
		region: REGIONS.MORAVSKOSLEZSKY
	},
	OSTRAVA_MESTO: {
		id: 65,
		name: 'Ostrava-město',
		seoName: 'ostrava-mesto',
		region: REGIONS.MORAVSKOSLEZSKY
	},
	HAVLICKUV_BROD: {
		id: 66,
		name: 'Havlíčkův Brod',
		seoName: 'havlickuv-brod',
		region: REGIONS.VYSOCINA
	},
	JIHLAVA: {
		id: 67,
		name: 'Jihlava',
		seoName: 'jihlava',
		region: REGIONS.VYSOCINA
	},
	PELHRIMOV: {
		id: 68,
		name: 'Pelhřimov',
		seoName: 'pelhrimov',
		region: REGIONS.VYSOCINA
	},
	TREBIC: {
		id: 69,
		name: 'Třebíč',
		seoName: 'trebic',
		region: REGIONS.VYSOCINA
	},
	'ZDAR_NAD-SAZAVOU': {
		id: 70,
		name: 'Žďár nad Sázavou',
		seoName: 'zdar-nad-sazavou',
		region: REGIONS.VYSOCINA
	},
	BLANSKO: {
		id: 71,
		name: 'Blansko',
		seoName: 'blansko',
		region: REGIONS.JIHOMORAVSKY
	},
	BRNO_MESTO: {
		id: 72,
		name: 'Brno-město',
		seoName: 'brno-mesto',
		region: REGIONS.JIHOMORAVSKY
	},
	BRNO_VENKOV: {
		id: 73,
		name: 'Brno-venkov',
		seoName: 'brno-venkov',
		region: REGIONS.JIHOMORAVSKY
	},
	BRECLAV: {
		id: 74,
		name: 'Břeclav',
		seoName: 'breclav',
		region: REGIONS.JIHOMORAVSKY
	},
	HODONIN: {
		id: 75,
		name: 'Hodonín',
		seoName: 'hodonin',
		region: REGIONS.JIHOMORAVSKY
	},
	VYSKOV: {
		id: 76,
		name: 'Vyškov',
		seoName: 'vyskov',
		region: REGIONS.JIHOMORAVSKY
	},
	ZNOJMO: {
		id: 77,
		name: 'Znojmo',
		seoName: 'znojmo',
		region: REGIONS.JIHOMORAVSKY
	}
}

const GEARBOX = {
	MANUAL: 'manualni',
	AUTOMATIC: 'automaticka',
	SEMI_AUTOMATIC: 'poloautomaticka'
}

const CEBIA_CATEGORY_ID = 'categoryId'

const USER_HISTORY_STATUSES = {
	user_disabled: 'Zakázání uživatele',
	user_enabled: 'Povolení uživatele',
	user_service_disabled: 'Zakázání služby',
	user_service_enabled: 'Povolení služby'
}

const CEBIA_BASE_URL = 'https://cz.cebia.com'

const SHOW_CAR_INSURANCE = false

export {
	SERVICE,
	SDN,
	toMetricHorsePowerConversionRate,
	exceptionalHeaderModelIds,
	CATEGORIES,
	CAR_BODY,
	ELECTRIC_VEHICLE_FUEL_OPTION_NAME,
	AVAILABILITY,
	CAR_CONDITION,
	RESPONSIVE,
	UPLOADER_CONSTANTS,
	INTERNAL_ADMIN,
	ADMIN_RIGHTS,
	COLOR_CODES,
	REGIONS,
	DISTRICTS,
	CEBIA_CATEGORY_ID,
	GEARBOX,
	USER_HISTORY_STATUSES,
	CEBIA_BASE_URL,
	SHOW_CAR_INSURANCE
}
